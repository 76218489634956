:root {
  --color-text: #464646;
  --core-text: #464646;
  --team-bg: #f5f6f6;
}

body {
  font-family: 'Nunito', sans-serif;
  color: var(--color-text);
}

.about-section-one {
  background: url('/public//Rectangle\ 3.svg') no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
}

.about-section-one p {
  max-width: 860px;
  font-weight: 860px;
}

.about-button Button {
  font-size: 16px;
  font-weight: bold;
}

.core-values-section h1 {
  color: var(--core-text);
}

.about-section-three {
  background-color: #04172a;
}

.couple-image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.team-leads {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
}

.about-section-four {
  background-color: var(--team-bg);
}

.couple-image img {
  width: 270px;
}

@media screen and (max-width: 1080px) {
  .couple-image img {
    width: 180px;
  }
}

@media screen and (max-width: 600px) {
  .couple-image img {
    width: 250px;
  }
}

@media screen and (max-width: 550px) {
  .couple-image img {
    width: 150px;
  }
}
